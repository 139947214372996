import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import Iframe from '../components/Iframe';

const DetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  // Example URLs for the iframes
  const iframeUrls: Record<string, string> = {
    '1': 'https://yahoo.com',
    '2': 'https://example.com/page2',
    // Add more URLs as needed
  };

  const iframeUrl = iframeUrls[id as keyof typeof iframeUrls]; // Use type assertion

  if (!iframeUrl) {
    // Handle invalid id or undefined iframeUrl
    return (
      <div>
        <p>Invalid URL</p>
        <Button component={Link} to="/" variant="contained" color="primary">
          Home
        </Button>
      </div>
    );
  }

  return (
    <div>
      <Button component={Link} to="/" variant="contained" color="primary">
        Home
      </Button>
      <div style={{ marginTop: '5%' }}>
        <Iframe src={iframeUrl} />
      </div>
    </div>
  );
};

export default DetailsPage;
