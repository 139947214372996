import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import CustomCard from '../components/Card';
import brandConfig from '../moobrandconfig.json';
import styles from '../styles/homeStyles.module.css';
import DisclosureBox from './Disclosures';



const HomePage: React.FC = () => {
  let cards = [
    { id: 1, image: './images/apply.png', title: 'Apply', url: 'https://acquire1uat.comenity.net/?clientName=nebella&kioskMode=true&mockIC=successCb' },
    { id: 2, image: './images/accountlookup.png', title: 'Account Lookup', url: 'https://acquire1uat.comenity.net/enhanced-checkout?clientName=nebella&mockECO=existingAccount' },
    { id: 3, image: './images/bpay_logo3.png', title: 'Bread Pay', url: 'https://aspire-sdk-demos.myshopify.com/pages/finance' },
    { id: 4, image: './images/ccagreement.png', title: 'Credit Card Agreement', url: 'https://d.comenity.net/ac/breadcashback/public/credit-card-agreement' },
    { id: 5, image: './images/prescreen.png', title: 'Prescreen Acceptance', url: 'https://google.com' },
    { id: 6, image: './images/productscan.png', title: 'Product Scan', url: '/scanner' },
    { id: 7, image: './images/selfcheckout.png', title: 'Self Checkout', url: 'https://webapp.mishipay.com/b5db488c-744e-4d19-a718-6081578a1b4c/sg/scan' },
    { id: 8, image: './images/visitus.png', title: 'Visit Us', url: 'https://aspire-sdk-demos.myshopify.com/' },

    // Add more cards as needed
    
  ];
  const urlParams = new URLSearchParams(window.location.search);
  const brandName = urlParams.get('clientname');
  let homelogo = './images/nebella.svg';

  const updatedCards: { id: number; image: string; title: string; url: string; }[] = [];

  // Find the brand configuration based on the brand name
  const brand = brandConfig.brandList.find((item) => item.brandname === brandName);
if(brand){
  console.log(brand.bpay);

  if (brand.applylink) {
    updatedCards.push({ 'id': 1, 'image': './images/apply.png', 'title': 'Apply', 'url': brand.applylink });
  }
  if(brand.alu){
    updatedCards.push({ 'id': 2, 'image': './images/accountlookup.png', 'title': 'Account Lookup', 'url': brand.alu});
  }
  if(brand.bpay){
    updatedCards.push({ 'id': 3, 'image': './images/bpay_logo3.png', 'title': 'Bread Pay', 'url': brand.bpay});
  }
  if(brand.cca){
    updatedCards.push({ 'id': 4, 'image': './images/ccagreement.png', 'title': 'Credit Card Agreement', 'url': brand.cca});

  }
  if(brand.prescreen){
    updatedCards.push({ 'id': 5, 'image': './images/prescreen.png', 'title': 'Prescreen Acceptance', 'url': brand.prescreen});
  }
  if(brand.scan){
    updatedCards.push({ 'id': 6, 'image': './images/productscan.png', 'title': 'Product Scan', 'url': brand.scan});
  }
  if(brand.selfcheckout){
    updatedCards.push({ 'id': 7, 'image': './images/selfcheckout.png', 'title': 'Self Checkout', 'url': brand.selfcheckout});

  }
  if(brand.contactus){
    updatedCards.push({ 'id': 8, 'image': './images/visitus.png', 'title': 'Visit Us', 'url': brand.contactus});

  }
 if(brand.brandlogo){
   homelogo=brand.brandlogo;
 }

  cards = updatedCards;
}

  return (
  

    <Container>
    <div className={styles.heroimagediv}>
          <img src={homelogo}className={styles.pageLogo} alt="My Image" />

          <Typography className={styles.PageTitle}>Apply for your credit card, lookup your account, Bread Pay and more!</Typography>

    </div>

    {/* Card grid */}
    <Grid container spacing={3}>
      {cards.map((card) => (
        <Grid item key={card.id} xs={12} sm={6} md={4} lg={3}>
             <CustomCard image={card.image} title={card.title} url={card.url}  />
        </Grid>
      ))}
    </Grid>
    <DisclosureBox/>
    
  </Container>
  );
};

export default HomePage;
