import React from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import styles from '../styles/homeStyles.module.css'
interface CardProps {
  image: string;
  title: string;
  url: string;
  // onClick: () => void;

}



const CustomCard: React.FC<CardProps> = ({ image, title, url }) => {
  const handleClick = () => {
    window.open(url, '_blank');
  };

  return (
    <Card className={styles.cardWrapper} >

      <CardActionArea  onClick={handleClick}>
        <CardMedia component="img" className={styles.cardImage} image={image} alt={title} />
        <CardContent  className={styles.cardContent}>
          <Typography variant="h5" className={styles.cardTitle}>{title}</Typography>
        </CardContent>
      </CardActionArea>
    
    </Card>
  );
};

export default CustomCard;
