import React from 'react';
import { Box, Typography } from '@mui/material';


const DisclosureBox = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#EAEAEA',
        padding: '1rem',
        marginTop: '3.5rem'
      }}
    >
      <Typography variant="body1" sx={{fontSize: '0.8em',color: '#0000008a'}}>*Available for purchases between $500 and up to $5000. Example payment is based on the listed product prices assuming a [3/6/12/18/24/36/39/48 (longest term available)] month term loan and a 9.99% APR. Subject to approval of credit application. Rates range from [0/5.99/6.99/7.99]% to 29.99% APR. APRs will vary depending on credit qualifications, loan amount, and term.
<br></br>
<br></br>

†Available for purchases between $500 and up to $5000. A temporary authorization hold will be placed on your debit or credit card to ensure your card will cover the first two payments. One payment will be immediately voided.
<br></br>
<br></br>

Bread® pay-over-time plans are loans made by Comenity Capital Bank.
<br></br>
<br></br>

1 Offer is exclusive to Bread Cashback American Express Credit Card holders enrolled in the Bread Cashback program. Cashback can be redeemed as statement credit or direct deposit. This rewards program is provided by Comenity Capital Bank and its terms may change at any time. Please reference the Rewards Terms and Conditions for full program details.
<br></br>
<br></br>

2 Certain terms, conditions, and exclusions apply. For more details, visit American Express.
<br></br>
<br></br>

3 For new accounts, as of October 2022: Variable Purchase APR of 18.49%, 25.99%, or 29.99% based on the Prime Rate. Balance Transfer APR of 26.99%. Variable Cash Advance APR of 29.99%, based on Prime Rate. Minimum Interest Charge is $2. Balance Transfer Fee of the greater of $10 or 5% of the transfer. Cash Advance Fee of the greater of $10 or 5% of the advance.
<br></br>
<br></br>

Credit card offers are subject to credit approval. American Express is a registered trademark of American Express. This Card is issued by Comenity Capital Bank pursuant to a license from American Express.</Typography>
    </Box>
  );
};

export default DisclosureBox;
