import React, { useState } from 'react';
import QrScanner from 'react-qr-scanner';
import Modal from 'react-modal';
import pricesheet from './inclabPricesheet_sorted.json';
import HomeLogo from '../components/HomeLogo';

const ScannerPage: React.FC = () => {
  const [scanResult, setScanResult] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [matchedItem, setMatchedItem] = useState<any>(null);

  const handleScan = (data: string | null) => {
    if (data) {
      console.log(data['text'])
      setScanResult(data);

      // Search for a matching item in the pricesheet
      const matchedItem = pricesheet.find((item: any) => item.EPC === data['text']);
      if (matchedItem) {
        setMatchedItem(matchedItem);
        setModalOpen(true);
      }
    }
  };

  const handleError = (error: any) => {
    console.error(error);
  };

  const closeModal = () => {
    setModalOpen(false);
    setMatchedItem(null);
  };

  return (
    <div>
      {/* Your logo component */}

      <HomeLogo />

      {/* QR code and 2D barcode scanner */}
      <QrScanner
        delay={500}
        // onError={handleError}
        onScan={handleScan}
        
        facingMode="environment"
      />

      {/* Modal to display matched item */}
      {/* <Modal isOpen={modalOpen} onRequestClose={closeModal}> */}
        {matchedItem && (
          <div>
            <h2>{matchedItem.Name}</h2>
            <p>{matchedItem.Description}</p>
            <p style={{fontWeight:'bold' }}>Price: ${matchedItem.Price}</p>
            <img src={matchedItem.Image} alt={matchedItem.Name}  style={{ marginTop: '5%', marginBottom:'5%' }} />
          </div>
        )}
        {/* <button onClick={closeModal}>Close</button>
      </Modal> */}
    </div>
  );
};

export default ScannerPage;
