import React from 'react';

interface IframeProps {
  src: string;
}

const Iframe: React.FC<IframeProps> = ({ src }) => {
  return <iframe src={src} title="iframe" />;
};

export default Iframe;
